import type { RouteProps } from 'react-router-dom'
import { matchPath, useLocation } from 'react-router-dom'

import { daysSince } from '../../helpers/date'
import { useEffectOnMount } from '../../hooks/use-effect-on-mount'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { getIsLoggedInWithSchibsted, showSimplifiedLoginWidget } from '../../vendor/schibsted-identity-client'
import { getShouldAskForCookieConsent } from '../cookie-consent'
import { useAuthContext } from '../../context/auth-context'
import { routes } from '../../routing/route-configs'

const MIN_DAYS_BETWEEN_SHOWING_POPUP = 14

type SimplifiedLoginState = {
  lastShownAt: number
}
function useSchibstedSimplifiedLoginAttempt() {
  const [simplifiedLoginState, setSimplifiedLoginState] =
    useLocalStorage<SimplifiedLoginState>('bbq-simplified-login')
  const authContext = useAuthContext()
  const isAuthenticated = authContext?.isAuthenticated
  const parsedTimestamp = simplifiedLoginState?.lastShownAt
    ? new Date(simplifiedLoginState?.lastShownAt)
    : null

  const attemptSimplifiedLogin = async () => {
    if (parsedTimestamp && daysSince(parsedTimestamp) < MIN_DAYS_BETWEEN_SHOWING_POPUP) {
      return
    }
    const isLoggedIn = isAuthenticated || (await getIsLoggedInWithSchibsted())

    if (isLoggedIn) {
      setSimplifiedLoginState(undefined)
      return
    }
    try {
      const shouldShowWidget = await showSimplifiedLoginWidget({
        returnTo: window.location.href,
      })

      if (shouldShowWidget === true) {
        setSimplifiedLoginState({ lastShownAt: new Date().getTime() })
      }
    } catch (_error) {
      /**
       * Apparently the only errors thrown by `showSimplifiedLoginWidget`
       * are when the widget should not be shown, so we just ignore them.
       * https://schibsted.github.io/account-sdk-browser/Identity.html
       */
    }
  }
  return { attemptSimplifiedLogin }
}

export function SchibstedSimplifiedLoginPopup() {
  const { pathname } = useLocation()
  const isPopupDisabledOnRoute = routes.some(
    (route) =>
      matchPath(pathname, route as RouteProps) &&
      'isSchibstedSimplifiedLoginDisabled' in route &&
      route.isSchibstedSimplifiedLoginDisabled,
  )
  const shouldAskForCookieConsent = getShouldAskForCookieConsent()
  const { attemptSimplifiedLogin } = useSchibstedSimplifiedLoginAttempt()

  useEffectOnMount(() => {
    if (!shouldAskForCookieConsent && !isPopupDisabledOnRoute) {
      attemptSimplifiedLogin()
    }
  })
  return null
}
