import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import {
  gtmCall,
  reportError,
  sendCustomGtagEvent,
  useToastContext,
  Category,
  HomeEventActions,
} from '@qasa/app'
import type { CreateHomeInput, NrFirsthandHousingUnitsEnum } from '@qasa/graphql'
import { HomeStatusEnum, HomeRentalTypeEnum } from '@qasa/graphql'

import { useAuthContext } from '../../context/auth-context'
import { CREATE_LISTING } from '../../data/graphql/mutations/create-listing'
import { CookieNames } from '../../helpers/cookie'
import { getErrorsMap } from '../../helpers/api-error'
import { getPath } from '../../routing/get-path'
import { UPDATE_USER } from '../../data/graphql/mutations'

import { trackListingCreated } from './listing-tracking.utils'
import { EVALUATE_FOR_SHORTCUT } from './evaluate-for-shortcut.gql'

type GetRedirectParams = {
  homeId: string
  isVacationHome: boolean
  isFromFindTenant?: boolean
  isPotentialShortcut?: boolean
}

export const getAfterCreateHomeRedirect = ({
  homeId,
  isVacationHome,
  isFromFindTenant,
  isPotentialShortcut,
}: GetRedirectParams) => {
  const isFromFindTenantSearch = isFromFindTenant ? 'isFromFindTenant=1' : undefined
  if (isPotentialShortcut) {
    return {
      pathname: `${getPath('shortcutOptIn', { homeId })}`,
      search: isFromFindTenantSearch,
    }
  }
  return {
    pathname: `${getPath('editListing', { homeId })}/${
      isVacationHome ? 'location-perks' : 'listing-details-intro'
    }`,
    search: isFromFindTenantSearch,
  }
}

type CreateHomeParams = {
  input: CreateHomeInput
  user: {
    isProfessional: boolean
    companyName: string | null
    orgNumber: string | null
    isRentingOutFirsthandHomes: boolean | null
    nrFirsthandHousingUnits: NrFirsthandHousingUnitsEnum | null
  }
  isFromFindTenant?: boolean
}

export function useCreateHome() {
  const { authBody } = useAuthContext()
  const { push } = useHistory()
  const { addToast } = useToastContext()
  const { t } = useTranslation('commons')
  const [updateUser] = useMutation(UPDATE_USER)
  const [createHomeMutation, { loading: isCreateHomeLoading }] = useMutation(CREATE_LISTING, {
    onCompleted: (data) => {
      gtmCall({
        event:
          data.createHome?.home?.rentalType === HomeRentalTypeEnum.vacation
            ? 'VACATION_HOME_CREATE'
            : 'HOME_CREATE',
        payload: data.createHome?.home,
      })
      gtmCall({
        event: 'HOME_ADD',
        payload: data.createHome?.home,
      })
      trackListingCreated({ homeId: data.createHome?.home?.id })
    },
  })
  const [evaluateForShortcut] = useMutation(EVALUATE_FOR_SHORTCUT)

  const createHome = useCallback(
    async ({ input, user, isFromFindTenant }: CreateHomeParams) => {
      try {
        const { data, errors: graphQLErrors } = await createHomeMutation({ variables: { input } })
        if (authBody?.uid && user.isRentingOutFirsthandHomes !== null) {
          // Don't await, because this is a hail mary attempt to save these user values. It's convenient if it works, but it's fine if it fails.
          updateUser({
            variables: {
              uid: authBody.uid,
              input: {
                rentingOutFirsthandHomes: user.isRentingOutFirsthandHomes,
                nrFirsthandHousingUnits: user.nrFirsthandHousingUnits,
              },
            },
          })
        }
        if (data?.createHome?.home) {
          const home = data.createHome.home
          const { data: evaluationData } = await evaluateForShortcut({ variables: { homeId: home.id } })
          const isVacationHome = input.rentalType === HomeRentalTypeEnum.vacation
          const isPotentialShortcut =
            evaluationData?.evaluateHomeForShortcut?.home?.status === HomeStatusEnum.proposed_shortcut
          const redirect = getAfterCreateHomeRedirect({
            homeId: home.id,
            isVacationHome,
            isFromFindTenant,
            isPotentialShortcut,
          })
          push(redirect)

          sendCustomGtagEvent({
            category: Category.HOME,
            action: isVacationHome ? HomeEventActions.CREATE_VACATION : HomeEventActions.CREATE,
            label: home.id,
          })
          Cookies.remove(CookieNames.NEW_LISTING_DRAFT)
        } else if (data?.createHome?.errors || graphQLErrors) {
          const errors = data?.createHome?.errors ? getErrorsMap(data?.createHome?.errors) : graphQLErrors
          throw new Error(JSON.stringify(errors))
        } else {
          throw new Error('unknown error')
        }
        //TODO: Error handling. add toasts and redirect correctly when receiving errors from backend. See use-create-home-application.tsx for example
      } catch (e) {
        push({ pathname: `/create-listing` })
        addToast({ message: t('unexpected_error') })
        reportError('unexpected createHome error', { error: e })
      }
    },
    [addToast, authBody?.uid, createHomeMutation, evaluateForShortcut, push, t, updateUser],
  )

  return { createHome, isCreateHomeLoading }
}
