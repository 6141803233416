import type { SVGProps } from 'react'

export function BlocketLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 56 18" {...props}>
      <path
        d="M0.0336914 14.827V3H3.2711V5.71559C3.62042 5.63994 3.97624 5.59897 4.33348 5.59327C7.17401 5.59327 8.8759 7.17649 8.8759 10.2066C8.8759 13.2717 7.05963 14.8217 4.02326 14.8217L0.0336914 14.827ZM3.2711 11.9943H3.86555C4.95566 11.9943 5.53451 11.3128 5.53451 10.2119C5.53451 9.11096 4.92273 8.49759 3.83262 8.49759C3.64375 8.50135 3.4557 8.52359 3.2711 8.56399V11.9943Z"
        fill="white"
      />
      <path d="M13.0006 3.00176H9.76321V14.827H13.0006V3.00176Z" fill="white" />
      <path
        d="M13.8932 10.28C13.8932 7.18175 15.5119 5.59853 18.5847 5.59853C21.6574 5.59853 23.2761 7.18175 23.2761 10.28C23.2761 13.3783 21.6574 14.9983 18.5847 14.9983C15.5119 14.9983 13.8932 13.3836 13.8932 10.28ZM19.9382 10.28C19.9382 9.31368 19.5413 8.56401 18.5847 8.56401C17.628 8.56401 17.2294 9.31368 17.2294 10.28C17.2294 11.2464 17.6263 12.0275 18.5847 12.0275C19.5431 12.0275 19.9382 11.2447 19.9382 10.28Z"
        fill="white"
      />
      <path
        d="M24.0022 10.28C24.0022 7.24816 25.7041 5.59853 28.6937 5.59853C29.4975 5.60888 30.2928 5.767 31.0403 6.06511V9.03583C30.4496 8.75407 29.8092 8.59368 29.1564 8.56401C28.0005 8.56401 27.3384 9.1966 27.3384 10.28C27.3384 11.3635 28.0005 12.0275 29.1564 12.0275C29.8086 11.9993 30.4489 11.8413 31.0403 11.5627V14.5334C30.293 14.8323 29.4976 14.9905 28.6937 15C25.7041 15 24.0022 13.3172 24.0022 10.2818"
        fill="white"
      />
      <path
        d="M35.2691 14.827H32.0317V3.00176H35.2691V8.73002L37.5169 5.76629H41.4649L38.276 9.72958L41.4649 14.827H37.5828L35.2691 10.8305V14.827Z"
        fill="white"
      />
      <path
        d="M40.9345 10.28C40.9345 7.21496 42.6035 5.59853 45.4405 5.59853C48.1181 5.59853 49.5549 7.34602 49.5549 10.142V11.0262H44.2395C44.3383 11.9419 45.2291 12.3246 46.4353 12.3246C47.5045 12.2903 48.5598 12.0704 49.5549 11.6745V14.273C48.3852 14.7533 47.1332 14.9967 45.8703 14.9895C42.5012 14.9895 40.931 13.3748 40.931 10.2713L40.9345 10.28ZM46.4474 9.18088C46.4474 8.48188 46.1008 7.95764 45.34 7.95764C44.5792 7.95764 44.2343 8.48188 44.2343 9.18088H46.4474Z"
        fill="white"
      />
      <path
        d="M50.451 10.2468V3.43165H53.6884V5.76804H56.0003V8.66362H53.6884V10.1787C53.6884 11.512 54.5549 11.9611 55.8357 11.9611H56.0003V14.855C55.6894 14.9088 55.3746 14.9363 55.0592 14.9371C52.035 14.9371 50.451 13.3049 50.451 10.2416"
        fill="white"
      />
    </svg>
  )
}
