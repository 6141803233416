import styled from '@emotion/styled'
import type { Market } from '@qasa/graphql'

import type { ZendeskArticleSection } from '../../helpers/zendesk-api'
import { FaqPanel } from '../../ui-page-modules/faq-panel'

import { PageContent } from './page-content'
import { PageWidthContainer } from './width-containers'

type PageChromeProps = {
  children: React.ReactNode
  helpCenterSection?: ZendeskArticleSection
  market?: Pick<Market, 'name'>
}

const FAQ_PANEL_WIDTH = 280

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
})

const ChildrenWrapper = styled.div({
  flex: 1,
})

const FaqWrapper = styled.div(({ theme }) => ({
  maxWidth: FAQ_PANEL_WIDTH,
  flex: 1,
  display: 'none',
  [theme.mediaQueries.mdUp]: {
    display: 'block',
    marginLeft: theme.spacing['12x'],
  },
}))

export function PageChrome({ children, helpCenterSection, market }: PageChromeProps) {
  return (
    <PageWidthContainer maxWidth="wider">
      <PageContent>
        <Wrapper>
          <ChildrenWrapper>{children}</ChildrenWrapper>
          {helpCenterSection && (
            <FaqWrapper>
              <FaqPanel section={helpCenterSection} market={market} />
            </FaqWrapper>
          )}
        </Wrapper>
      </PageContent>
    </PageWidthContainer>
  )
}
