import type { ComponentProps } from 'react'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { createIcon, Heading, LoadingDots, Paragraph } from '@qasa/qds-ui'
import { reportError } from '@qasa/app'

import type { ZendeskArticleSection } from '../../helpers/zendesk-api'
import { PageChrome } from '../../ui-shared/_layout/page-chrome'
import { Wizard } from '../wizard'

const Flex = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const Wrapper = styled.div({
  marginBottom: 50,
  marginTop: 50,
})

const ErrorTitleRow = styled.div(({ theme }) => ({
  display: 'flex',
  marginBottom: 16,
  gap: theme.spacing['2x'],
  alignItems: 'center',
}))

const ErrorCard = styled.div(({ theme }) => ({
  maxWidth: 440,
  border: '1px solid',
  borderColor: theme.colors.border.default,
  borderRadius: theme.radii.md,
  padding: 40,
}))

export function Loading({ testId, ...props }: ComponentProps<typeof Wrapper> & { testId?: string }) {
  return (
    <Wrapper {...props}>
      <Flex>
        <LoadingDots data-testid={testId} />
      </Flex>
    </Wrapper>
  )
}

const TriangleExclamationSolidIcon = createIcon({
  viewBox: '0 0 512 512',
  d: 'M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z',
})

type ErrorProps = {
  error: Error
}
export function Error({ error }: ErrorProps) {
  const { t } = useTranslation('network_statuses')
  useEffect(() => {
    reportError(error.message, {
      stack: error.stack || 'Empty stacktrace',
      error,
    })
  }, [error])

  return (
    <Wrapper>
      <Flex>
        <ErrorCard>
          <ErrorTitleRow>
            <TriangleExclamationSolidIcon size={24} color="negative" />
            <Heading as="div">{t('error.title')}</Heading>
          </ErrorTitleRow>
          <Paragraph>{t('error.message')}</Paragraph>
        </ErrorCard>
      </Flex>
    </Wrapper>
  )
}

export function PageNetworkStatus({
  isLoading,
  error,
  ...pageChromeProps
}: {
  isLoading?: boolean
  error?: Error
  helpCenterSection?: ZendeskArticleSection
}) {
  if (isLoading) {
    return (
      <PageChrome {...pageChromeProps}>
        <Loading />
      </PageChrome>
    )
  }

  if (error) {
    return (
      <PageChrome {...pageChromeProps}>
        <Error error={error} />
      </PageChrome>
    )
  }

  return null
}

export function WizardNetworkStatus({
  isLoading,
  error,
  closeLink,
}: {
  isLoading?: boolean
  error?: Error
  closeLink?: string
}) {
  if (isLoading) {
    return (
      <Wizard closeLink={closeLink}>
        <Loading />
      </Wizard>
    )
  }

  if (error) {
    return (
      <Wizard closeLink={closeLink}>
        <Error error={error} />
      </Wizard>
    )
  }

  return null
}

export function NetworkStatus({ isLoading, error }: { isLoading?: boolean; error?: Error }) {
  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return null
}
