import styled from '@emotion/styled'
import type { ReactNode } from 'react'
import { Heading, Link, Paragraph, Stack, XIcon } from '@qasa/qds-ui'

import { useLocalStorageWithListener } from '../hooks/use-local-storage-with-listener'
import { isDesktop } from '../ui-styles/media-queries'

type BannerProps = {
  variant?: 'alert' | 'info'
  name: string
  children: ReactNode
}

const Wrapper = styled(Stack)<{ $variant?: BannerProps['variant'] }>(({ $variant = 'info', theme }) => ({
  padding: 16,
  [isDesktop]: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  height: 'fit-content',
  ...($variant === 'alert' && {
    /* temporary fix to have a somewhat lighter background without having it be semi-transparent */
    background: `linear-gradient(${theme.colors.core.blue20}33, ${theme.colors.core.blue20}33), linear-gradient(white, white)`,
  }),
  ...($variant === 'info' && {
    backgroundColor: theme.colors.core.gray20,
  }),
}))
const Content = styled.div({
  flex: 1,
})
const CloseButton = styled.button({
  padding: 8,
  margin: -8,
})

const Caption = styled(Heading)(({ theme }) => ({
  ...theme.typography.title['2xs'],
})).withComponent('span')
const Description = styled(Paragraph)({}).withComponent('span')
const LearnMoreLink = styled(Link)({
  display: 'inline',
})

export function Banner({ variant, name, children }: BannerProps) {
  const [hasClosedBanner, setHasClosedBanner] = useLocalStorageWithListener(name + 'Hidden', false)

  if (hasClosedBanner) {
    return null
  }
  return (
    <Wrapper
      $variant={variant}
      gap="6x"
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Content>{children}</Content>
      <CloseButton onClick={() => setHasClosedBanner(true)}>
        <XIcon size={16} />
      </CloseButton>
    </Wrapper>
  )
}

Banner.Wrapper = Wrapper
Banner.Caption = Caption
Banner.Description = Description
Banner.LearnMoreLink = LearnMoreLink
