import { lazy } from 'react'

export const Home = lazy(() => import('../legacy-pages/home').then(({ Home }) => ({ default: Home })))
export const OtpLogin = lazy(() =>
  import('../legacy-pages/otp-login-page').then(({ OtpLoginPage }) => ({ default: OtpLoginPage })),
)
export const AdminLogin = lazy(() =>
  import('../ui-page-modules/authentication-flow/admin-login').then(({ AdminLogin }) => ({
    default: AdminLogin,
  })),
)
export const Signup = lazy(() =>
  import('../legacy-pages/signup-page').then(({ SignupPage }) => ({ default: SignupPage })),
)
export const PostSignup = lazy(() =>
  import('../legacy-pages/post-signup-page').then(({ PostSignupPage }) => ({ default: PostSignupPage })),
)
export const ResetPasswordRequest = lazy(() =>
  import('../legacy-pages/reset-password-request-page').then(({ ResetPasswordRequestPage }) => ({
    default: ResetPasswordRequestPage,
  })),
)
export const ResetPassword = lazy(() =>
  import('../legacy-pages/reset-password-page').then(({ ResetPasswordPage }) => ({
    default: ResetPasswordPage,
  })),
)
export const ProfilePage = lazy(() =>
  import('../legacy-pages/profile-page').then(({ ProfilePage }) => ({ default: ProfilePage })),
)
export const Settings = lazy(() =>
  import('../legacy-pages/settings').then(({ Settings }) => ({ default: Settings })),
)
export const Cookies = lazy(() =>
  import('../legacy-pages/cookies').then(({ Cookies }) => ({ default: Cookies })),
)
export const AddBankAccount = lazy(() =>
  import('../legacy-pages/add-bank-account').then(({ AddBankAccount }) => ({ default: AddBankAccount })),
)
export const Contracts = lazy(() =>
  import('../legacy-pages/contracts').then(({ Contracts }) => ({ default: Contracts })),
)
export const ContractInvoices = lazy(() =>
  import('../legacy-pages/contract-invoices').then(({ ContractInvoices }) => ({ default: ContractInvoices })),
)
export const ExtendContract = lazy(() =>
  import('../legacy-pages/extend-contract').then(({ ExtendContract }) => ({ default: ExtendContract })),
)
export const ClaimContractInvitationIntro = lazy(() =>
  import('../legacy-pages/claim-contract-invitation-intro-page').then(
    ({ ClaimContractInvitationIntroPage }) => ({
      default: ClaimContractInvitationIntroPage,
    }),
  ),
)
export const ClaimContractInvitation = lazy(() =>
  import('../legacy-pages/claim-contract-invitation-page').then(({ ClaimContractInvitationPage }) => ({
    default: ClaimContractInvitationPage,
  })),
)
export const IdVerificationModule = lazy(() =>
  import('../legacy-pages/id-verification-module').then(({ IdVerificationModule }) => ({
    default: IdVerificationModule,
  })),
)
export const Messages = lazy(() =>
  import('../legacy-pages/messages').then(({ Messages }) => ({ default: Messages })),
)
export const ContractSendSuccessWizard = lazy(() =>
  import('../legacy-pages/contract-send-success-wizard').then(({ ContractSendSuccessWizard }) => ({
    default: ContractSendSuccessWizard,
  })),
)
export const HomePublishSuccess = lazy(() =>
  import('../legacy-pages/home-publish-success').then(({ HomePublishSuccess }) => ({
    default: HomePublishSuccess,
  })),
)
export const MyListings = lazy(() =>
  import('../legacy-pages/my-listings').then(({ MyListings }) => ({ default: MyListings })),
)
export const ListingDashboard = lazy(() =>
  import('../legacy-pages/listing-dashboard/listing-dashboard').then(({ ListingDashboard }) => ({
    default: ListingDashboard,
  })),
)
export const EditListing = lazy(() =>
  import('../legacy-pages/edit-listing').then(({ EditListing }) => ({ default: EditListing })),
)
export const CreateListing = lazy(() =>
  import('../legacy-pages/create-listing').then(({ CreateListing }) => ({ default: CreateListing })),
)

export const CreateListingLanding = lazy(() =>
  import('../legacy-pages/create-listing-landing/index').then(({ CreateListingLanding }) => ({
    default: CreateListingLanding,
  })),
)
export const TenantContractSignLanding = lazy(() =>
  import('../legacy-pages/tenant-contract-sign-landing').then(({ TenantContractSignLanding }) => ({
    default: TenantContractSignLanding,
  })),
)
export const LandlordContractSignLanding = lazy(() =>
  import('../legacy-pages/landlord-contract-sign-landing').then(({ LandlordContractSignLanding }) => ({
    default: LandlordContractSignLanding,
  })),
)
export const AdminLoginAsUser = lazy(() =>
  import('../legacy-pages/admin-login-as-user').then(({ AdminLoginAsUser }) => ({
    default: AdminLoginAsUser,
  })),
)
export const LandlordCalendar = lazy(() =>
  import('../ui-page-modules/listing/sections/landlord-calendar/landlord-calendar').then(
    ({ LandlordCalendar }) => ({ default: LandlordCalendar }),
  ),
)
export const FindVacation = lazy(() =>
  import('../legacy-pages/find-vacation-page').then(({ FindVacationPage }) => ({
    default: FindVacationPage,
  })),
)
export const CreateTenantListingPage = lazy(() =>
  import('../legacy-pages/create-tenant-listing-page').then(({ CreateTenantListingPage }) => ({
    default: CreateTenantListingPage,
  })),
)
export const FindTenant = lazy(() =>
  import('../legacy-pages/find-tenant').then(({ FindTenant }) => ({ default: FindTenant })),
)
export const ReservationSummary = lazy(() =>
  import('../legacy-pages/reservation-summary').then(({ ReservationSummary }) => ({
    default: ReservationSummary,
  })),
)
export const ReservationSuccess = lazy(() =>
  import('../legacy-pages/reservation-success').then(({ ReservationSuccess }) => ({
    default: ReservationSuccess,
  })),
)
export const ProfilePublishSuccess = lazy(() =>
  import('../legacy-pages/profile-publish-success').then(({ ProfilePublishSuccess }) => ({
    default: ProfilePublishSuccess,
  })),
)
export const Logout = lazy(() => import('./logout').then(({ Logout }) => ({ default: Logout })))
export const MessagesRedirect = lazy(() =>
  import('./messages-redirect').then(({ MessagesRedirect }) => ({ default: MessagesRedirect })),
)
export const EmbeddedFindHome = lazy(() =>
  import('../legacy-pages/embedded-find-home').then(({ EmbeddedFindHome }) => ({
    default: EmbeddedFindHome,
  })),
)
export const VerifyBankAccountPage = lazy(() =>
  import('../legacy-pages/verify-bank-account-page').then(({ VerifyBankAccountPage }) => ({
    default: VerifyBankAccountPage,
  })),
)
export const InstantMatch = lazy(() =>
  import('../legacy-pages/instant-match').then(({ InstantMatch }) => ({ default: InstantMatch })),
)
export const Favorites = lazy(() =>
  import('../legacy-pages/favorites').then(({ Favorites }) => ({
    default: Favorites,
  })),
)
export const ContentfulPage = lazy(() =>
  import('../legacy-pages/contentful-page').then(({ ContentfulPage }) => ({
    default: ContentfulPage,
  })),
)
export const ConfirmEmailPage = lazy(() =>
  import('../legacy-pages/confirm-email-page').then(({ ConfirmEmailPage }) => ({
    default: ConfirmEmailPage,
  })),
)
export const Help = lazy(() =>
  import('../legacy-pages/help').then(({ Help }) => ({
    default: Help,
  })),
)
export const Invite = lazy(() =>
  import('../legacy-pages/invite').then(({ Invite }) => ({
    default: Invite,
  })),
)
export const InstantSign = lazy(() =>
  import('../legacy-pages/instant-sign').then(({ InstantSign }) => ({ default: InstantSign })),
)
export const LegalTermsAndConditions = lazy(() =>
  import('../legacy-pages/legal-terms-and-conditions').then(({ LegalTermsAndConditions }) => ({
    default: LegalTermsAndConditions,
  })),
)
export const FirsthandContract = lazy(() =>
  import('../legacy-pages/firsthand-contract').then(({ FirsthandContract }) => ({
    default: FirsthandContract,
  })),
)
export const HomeqRedirectPage = lazy(() =>
  import('../legacy-pages/homeq-redirect-page').then(({ HomeqRedirectPage }) => ({
    default: HomeqRedirectPage,
  })),
)
