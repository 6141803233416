import pick from 'lodash/pick'
import { matchPath, useLocation } from 'react-router-dom'
import { useBreakpointValue } from '@qasa/qds-ui'

import type { RouteConfig, RoutePageOptions } from './route-configs'
import { routes } from './route-configs'

const getRouteDeclaration = (pathname: string) => {
  return routes.find((route) => {
    const isExact = 'isExact' in route ? route.isExact : true
    return matchPath(pathname, { path: route.path, exact: isExact })
  })
}

type GetPageOptionsParams = {
  routeDeclaration: RouteConfig
  isMobile: boolean
}

const getPageOptions = ({ routeDeclaration, isMobile }: GetPageOptionsParams): RoutePageOptions => {
  const defaultPageOptions = pick<RoutePageOptions>(routeDeclaration, [
    'wizard',
    'isNavBarHidden',
    'isFooterHidden',
    'isSunshineDisabled',
    'isSchibstedSimplifiedLoginDisabled',
    'schibstedFooterOffset',
    'schibstedFooterStyle',
    'footerSpacing',
  ])
  const mobileOverride = 'mobileOverride' in routeDeclaration ? routeDeclaration.mobileOverride : {}

  return {
    ...defaultPageOptions,
    ...(isMobile && mobileOverride),
  }
}

export const usePageOptions = (): RoutePageOptions => {
  const { pathname } = useLocation()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const routeDeclaration = getRouteDeclaration(pathname)

  if (!routeDeclaration) return {}

  return getPageOptions({ routeDeclaration, isMobile })
}
