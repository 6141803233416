import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Spacer } from '@qasa/qds-ui'

import { Footer } from '../ui-page-modules/footer/footer'
import { TopNavBar } from '../ui-page-modules/top-nav-bar'
import { SchibstedFooter as DefaultSchibstedFooter } from '../ui-page-modules/_brand-specific/schibsted-footer'
import { currentBrand } from '../config'
import { SchibstedMarketplaceFooter } from '../ui-shared/schibsted-marketplace-footer'

import { usePageOptions } from './use-page-options'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
})
const ContentWrapper = styled.div({
  flex: 1,
})

type Props = {
  children: ReactNode
}
export function GlobalPageLayout({ children }: Props) {
  const isQasaFinland = currentBrand === 'qasa_finland'
  const isNativeApp = window.isNativeApp
  const {
    wizard: isWizard,
    isNavBarHidden,
    isFooterHidden,
    schibstedFooterOffset,
    schibstedFooterStyle,
    footerSpacing = 'default',
  } = usePageOptions()

  const isWizardOrNative = isWizard || isNativeApp
  const shouldHideNavbar = isNavBarHidden || isWizardOrNative
  const shouldHideFooter = isFooterHidden || isWizardOrNative
  const shouldRenderFooterSpacing = footerSpacing === 'default' && !shouldHideFooter

  const shouldShowSchibstedFooter = isQasaFinland && schibstedFooterOffset !== 'hidden'
  const schibstedFooterOffsetValue = typeof schibstedFooterOffset === 'number' ? schibstedFooterOffset : 0
  function SchibstedFooter() {
    return schibstedFooterStyle === 'dark' ? (
      <SchibstedMarketplaceFooter />
    ) : (
      <DefaultSchibstedFooter bottomOffset={schibstedFooterOffsetValue} />
    )
  }

  return (
    <Wrapper data-testid="global-page-wrapper">
      {!shouldHideNavbar && <TopNavBar />}
      <ContentWrapper>{children}</ContentWrapper>
      {shouldRenderFooterSpacing && <Spacer size="8x" />}
      {!shouldHideFooter && <Footer />}
      {shouldShowSchibstedFooter && <SchibstedFooter />}
    </Wrapper>
  )
}
