import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { AdminBox } from '../ui-page-modules/admin-box'
import { MetaTags } from '../ui-page-modules/meta-tags'

type Props = {
  children: ReactNode
  i18nTitle?: string
}

export function DefaultPageWrapper({ children, i18nTitle }: Props) {
  const { t } = useTranslation('page_titles')

  return (
    <>
      <AdminBox />
      <MetaTags title={i18nTitle && t(i18nTitle)} />
      {children}
    </>
  )
}
