'use client'

import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'
import { useState } from 'react'

import { useCurrentLocale } from '../../../../translations/use-current-locale'
import { GlobalPageLayout } from '../../../../routing/global-page-layout'
import { ScrollToTop } from '../../../../helpers/scroll'
import { Routes } from '../../../../routing/routes'
import { ClientOnly } from '../../../../ui-shared/client-only'

export function ClientApp() {
  const locale = useCurrentLocale()

  const [browserRouterKey, setBrowserRouterKey] = useState<string | undefined>(undefined)

  const onRouteMismatch = (pathnameKey: string) => {
    setBrowserRouterKey(pathnameKey)
  }

  return (
    <ClientOnly
      // Force a re-render when the react router gets out of sync with the next router
      key={browserRouterKey}
    >
      <BrowserRouter basename={`/${locale}`}>
        <QueryParamProvider
          ReactRouterRoute={Route}
          stringifyOptions={{
            transformSearchString: transformSearchStringJsonSafe,
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma',
          }}
        >
          <GlobalPageLayout>
            <Routes onRouteMismatch={onRouteMismatch} />
            <ScrollToTop />
          </GlobalPageLayout>
        </QueryParamProvider>
      </BrowserRouter>
    </ClientOnly>
  )
}
