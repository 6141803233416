import styled from '@emotion/styled'
import type { StackProps } from '@qasa/qds-ui'
import { Stack } from '@qasa/qds-ui'
import type { ReactNode } from 'react'

import { WIZARD_CONTENT_MAX_WIDTH } from './wizard/constants'

const FloatingFooter = styled.footer<Pick<DialogAndWizardActionsFooterProps, 'isFooterStickyOnDesktop'>>(
  ({ theme, isFooterStickyOnDesktop }) => ({
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    paddingInline: theme.sizes['4x'],
    paddingBlock: theme.sizes['4x'],
    borderTop: `1px solid ${theme.colors.border.default}`,
    background: theme.colors.bg.default,
    ...(!isFooterStickyOnDesktop && {
      [theme.mediaQueries.mdUp]: {
        position: 'relative',
        borderTop: 'none',
        paddingBlock: 0,
        paddingInline: 0,
      },
    }),
  }),
)
/* Make sure footer doesn't cover the content when the page overflows */
const FooterSpacer = styled.div<Pick<DialogAndWizardActionsFooterProps, 'isFooterStickyOnDesktop'>>(
  ({ theme, isFooterStickyOnDesktop }) => ({
    marginTop: '4.5rem',
    ...(!isFooterStickyOnDesktop && {
      [theme.mediaQueries.mdUp]: {
        display: 'none',
      },
    }),
  }),
)

type DialogAndWizardActionsFooterProps = {
  children: ReactNode
  justifyContent?: StackProps['justifyContent']
  isFooterStickyOnDesktop?: boolean
}
export function DialogAndWizardActionsFooter({
  children,
  justifyContent = 'space-between',
  isFooterStickyOnDesktop,
}: DialogAndWizardActionsFooterProps) {
  return (
    <>
      <FooterSpacer isFooterStickyOnDesktop={isFooterStickyOnDesktop} />
      <FloatingFooter isFooterStickyOnDesktop={isFooterStickyOnDesktop}>
        <Stack
          style={{ maxWidth: WIZARD_CONTENT_MAX_WIDTH, width: '100%' }}
          direction={'row'}
          gap={'2x'}
          justifyContent={justifyContent}
        >
          {children}
        </Stack>
      </FloatingFooter>
    </>
  )
}
