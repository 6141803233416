import type { SVGProps } from 'react'

export function HomeqLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 73 18" {...props}>
      <path
        d="M27.6768 8.29977H22.1171V3.65697H20.2374V14.4553H22.1171V10.0212H27.6768V14.4553H29.53V3.65697H27.6768V8.29977Z"
        fill="white"
      />
      <path
        d="M27.6768 8.29977H22.1171V3.65697H20.2374V14.4553H22.1171V10.0212H27.6768V14.4553H29.53V3.65697H27.6768V8.29977Z"
        fill="white"
      />
      <path
        d="M34.6395 6.65663C33.4481 6.65663 32.495 7.04786 31.7537 7.77818C31.0124 8.53459 30.6418 9.49969 30.6418 10.6212C30.6418 11.7689 31.0124 12.7079 31.7537 13.4643C32.495 14.2207 33.4481 14.5858 34.6395 14.5858C35.8308 14.5858 36.8104 14.1946 37.5517 13.4643C38.2929 12.7079 38.6636 11.7689 38.6636 10.6212C38.6636 9.49969 38.2929 8.53459 37.5517 7.77818C36.8104 7.04786 35.8308 6.65663 34.6395 6.65663ZM33.0245 8.95191C33.4216 8.48241 33.9776 8.27377 34.666 8.27377C35.3278 8.27377 35.8573 8.5085 36.2809 8.978C36.7045 9.4475 36.9163 10.0213 36.9163 10.6734C36.9163 11.3516 36.7045 11.8993 36.3074 12.3688C35.9103 12.8122 35.3543 13.047 34.666 13.047C33.9776 13.047 33.4216 12.8122 33.0245 12.3688C32.6274 11.9254 32.4156 11.3516 32.4156 10.6734C32.3891 9.96914 32.6009 9.39532 33.0245 8.95191Z"
        fill="white"
      />
      <path
        d="M48.4859 6.68228C47.321 6.68228 46.4473 7.15179 45.8914 8.09074C45.4678 7.15179 44.6736 6.68228 43.4822 6.68228C43.0322 6.68228 42.6086 6.7866 42.2114 6.96919C41.8673 7.1257 41.6025 7.36042 41.4172 7.64733L41.3378 6.7866H39.7228V14.455H41.4701V10.2557C41.4701 9.70789 41.629 9.23843 41.9732 8.84716C42.2909 8.48202 42.7144 8.27334 43.2439 8.27334C43.7734 8.27334 44.1706 8.45593 44.4883 8.82107C44.8059 9.18625 44.9383 9.68185 44.9383 10.2296V14.429H46.6856V10.2296C46.6856 9.68185 46.8445 9.21234 47.1887 8.84716C47.5063 8.48202 47.9564 8.29943 48.4859 8.29943C49.0156 8.29943 49.4125 8.48202 49.6773 8.82107C49.9421 9.18625 50.1007 9.65575 50.1007 10.2035V14.429H51.8481V10.2035C51.8481 9.08193 51.5568 8.2212 51.0012 7.5952C50.3655 6.99529 49.5451 6.68228 48.4859 6.68228Z"
        fill="white"
      />
      <path
        d="M56.6931 6.60435C55.5014 6.60435 54.4953 6.96953 53.754 7.72595C53.0128 8.45627 52.6158 9.42132 52.6158 10.595C52.6158 11.821 53.0128 12.8121 53.754 13.5424C54.5218 14.2727 55.5279 14.6379 56.7987 14.6379C57.4343 14.6379 58.043 14.5336 58.6521 14.2988C59.2607 14.0641 59.7638 13.7511 60.1346 13.3338L60.1877 13.2816L59.049 12.1862L58.996 12.2383C58.7843 12.4731 58.4669 12.6556 58.0695 12.8121C57.6726 12.9686 57.2487 13.0208 56.8517 13.0208C56.1901 13.0208 55.634 12.8643 55.184 12.5252C54.7866 12.2122 54.5218 11.7949 54.4427 11.2993H60.6111V11.2211C60.7438 9.73432 60.4525 8.56059 59.7377 7.77808C59.0225 7.02167 57.9904 6.60435 56.6931 6.60435ZM54.4693 9.81259C54.6014 9.29091 54.8662 8.89968 55.2366 8.61277C55.6605 8.29977 56.1635 8.14327 56.7196 8.14327C57.3548 8.14327 57.8578 8.29977 58.2547 8.58668C58.6256 8.87359 58.8373 9.26482 58.8904 9.7865H54.4693V9.81259Z"
        fill="white"
      />
      <path
        d="M71.4394 12.447C72.1541 11.4558 72.4985 10.2821 72.4719 8.95186C72.4719 8.24763 72.3393 7.5434 72.1011 6.9174C71.8628 6.2653 71.5455 5.69148 71.095 5.16984C70.6451 4.64816 70.0629 4.23084 69.3477 3.91784C68.633 3.60484 67.7861 3.44835 66.8857 3.44835C65.9857 3.44835 65.1383 3.60484 64.4236 3.91784C63.7089 4.23084 63.0997 4.64816 62.6498 5.19589C62.1998 5.71757 61.8554 6.31748 61.6172 6.96958C61.3789 7.62163 61.2733 8.32586 61.2733 9.05618C61.2733 9.7865 61.3789 10.4908 61.6172 11.1428C61.8554 11.7949 62.1733 12.3948 62.6232 12.9165C63.0732 13.4382 63.6558 13.8816 64.3971 14.1945C65.1383 14.5075 65.9592 14.664 66.8857 14.664C68.1565 14.664 69.2156 14.3771 70.089 13.7772L71.4128 15.0553L72.7102 13.7512L71.4394 12.447ZM66.8857 12.8904C65.7209 12.8904 64.7945 12.4992 64.1323 11.7427C63.4706 10.9863 63.1528 10.0734 63.1263 9.05618C63.1263 8.37804 63.2323 7.75204 63.4971 7.15212C63.7619 6.5783 64.1853 6.08271 64.7679 5.69148C65.3501 5.30025 66.0648 5.11766 66.8591 5.11766C67.4948 5.11766 68.0508 5.22198 68.5273 5.43066C69.0038 5.6393 69.4008 5.92626 69.6921 6.29139C69.9834 6.65658 70.1951 7.04781 70.3538 7.49122C70.5129 7.93463 70.592 8.40413 70.592 8.87364C70.6185 9.943 70.3012 10.882 69.639 11.6645C69.0304 12.4992 68.0769 12.8904 66.8857 12.8904Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.44308 0.528348C6.31105 -0.175294 7.55934 -0.176208 8.42837 0.526163L12.6535 3.94101C13.2024 4.38464 13.5208 5.04913 13.5208 5.7508V11.6807L11.7687 10.6847V5.7508C11.7687 5.57038 11.6868 5.39951 11.5457 5.28545L7.32056 1.87059C7.09707 1.68999 6.77611 1.69022 6.55291 1.87116L2.34217 5.28468C2.20143 5.39874 2.11983 5.56937 2.11983 5.7495L2.11983 11.2082C2.11983 12.8963 3.50158 14.2647 5.20604 14.2647C6.55875 14.2647 7.64022 13.4275 8.04722 12.4747L5.29179 10.5855C4.41793 9.99596 3.895 9.01654 3.895 7.96935C3.895 6.30171 5.26001 4.94987 6.9438 4.94987C8.60643 4.94987 9.96251 6.26916 9.99212 7.91553C10.0143 9.14463 9.99212 10.4151 9.99212 11.5986L13.5208 13.7812V15.9303L9.52223 13.3665C8.79669 14.8299 7.13373 16 5.20604 16C2.5339 16 0.367683 13.8547 0.36768 11.2082L0.367676 5.7495C0.367675 5.04903 0.685054 4.38554 1.23235 3.94186L5.44308 0.528348ZM8.28899 10.5078L8.24028 7.94645C8.22769 7.24627 7.65093 6.68513 6.9438 6.68513C6.22766 6.68513 5.64714 7.26011 5.64714 7.96935C5.64714 8.44247 5.88341 8.88492 6.2782 9.15129L8.28899 10.5078Z"
        fill="white"
      />
    </svg>
  )
}
