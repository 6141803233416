import styled from '@emotion/styled'

import { Banner } from '../../ui-shared/banner'

import { SchibstedControllerInfo } from './schibsted-controller-info'

const StyledFooter = styled(Banner.Wrapper)<{ bottomOffset: number }>(({ bottomOffset }) => ({
  /* Offsets for footer step navigation wizards  */
  marginBottom: bottomOffset,
})).withComponent('footer')

const CenteredContent = styled.div(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  color: theme.colors.text.subtle,
}))

export function SchibstedFooter({ bottomOffset }: { bottomOffset: number }) {
  return (
    <StyledFooter bottomOffset={bottomOffset}>
      <CenteredContent>
        <SchibstedControllerInfo />
      </CenteredContent>
    </StyledFooter>
  )
}
