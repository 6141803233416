import styled from '@emotion/styled'
import { Paragraph, Stack, useBreakpointValue } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { SchibstedLogo } from '../assets/logos/schibsted/schibsted'
import { QasaLogo } from '../assets/logos/qasa/qasa-logo'
import { ToriLogo } from '../assets/logos/qasa-finland/tori'
import { OikotieLogo } from '../assets/logos/qasa-finland/oikotie'
import { BlocketLogo } from '../assets/logos/blocket/blocket'
import { HomeqLogo } from '../assets/logos/homeq/homeq'

const Footer = styled.footer(({ theme }) => ({
  backgroundColor: theme.colors.core.brownDark,
  padding: theme.spacing['4x'],
  [theme.mediaQueries.mdUp]: {
    paddingInline: theme.spacing['6x'],
    paddingBlock: theme.spacing['5x'],
  },
}))

export function SchibstedMarketplaceFooter() {
  const { t } = useTranslation('schibsted_marketplace_footer')

  const isMdOrAbove = useBreakpointValue({ base: false, md: true })

  return (
    <Footer>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        gap={{ base: '4x', md: '8x' }}
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          alignItems="center"
          gap={'3x'}
          style={{ columnGap: 24 }}
          wrap="wrap"
        >
          <SchibstedLogo height={16} color="white" />
          <Paragraph size="xs" color="onBrandSecondary" style={{ position: 'relative', top: 2 }}>
            {t('description')}
          </Paragraph>
        </Stack>
        <Stack direction="row" gap={{ base: '5x', md: '6x' }} alignItems="center">
          <QasaLogo height={isMdOrAbove ? 18 : 16} color="white" style={{ position: 'relative', top: 4 }} />
          <HomeqLogo height={isMdOrAbove ? 18 : 16} style={{ position: 'relative', top: 2 }} />
          <ToriLogo height={isMdOrAbove ? 15 : 14} color="white" />
          <OikotieLogo height={isMdOrAbove ? 15 : 14} color="white" />
          <BlocketLogo height={isMdOrAbove ? 18 : 16} />
        </Stack>
      </Stack>
    </Footer>
  )
}
