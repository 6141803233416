import { useMemo, useState, useEffect } from 'react'

/**
 * Like useState but persisting values in localStorage.
 * If there is no value in localstorage intially, then defaultValue will be used as fallback.
 *
 * @param localStorageKey - a key of localstorage
 * @param defaultValue - fallback to use if localstorage value is undefined
 */
export function useLocalStorageWithListener<T>(localStorageKey: string, defaultValue: T): [T, SetState<T>] {
  const initialStorageValue: T = useMemo(() => {
    const initial = window.localStorage.getItem(localStorageKey)
    return initial ? JSON.parse(initial) : undefined
  }, [localStorageKey])

  const [value, setValue] = useState<T>(initialStorageValue ?? defaultValue)

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(value))
  }, [value, localStorageKey])

  useEffect(() => {
    const triggerUpdate = () => {
      const newValue = window.localStorage.getItem(localStorageKey)
      if (newValue !== null) {
        setValue(JSON.parse(newValue))
      }
    }

    window.addEventListener('storage', triggerUpdate)
    return () => {
      window.removeEventListener('storage', triggerUpdate)
    }
  }, [localStorageKey])

  return [value, setValue]
}
