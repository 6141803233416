'use client'
import Cookies from 'js-cookie'

import { CookieNames } from '../../../../helpers/cookie'

import { ClientApp } from './client-app'

export default function Page() {
  const localeCookie = Cookies.get(CookieNames.SELECTED_LANGUAGE)

  // This cookie rewrite logic can be removed after some weeks
  if (localeCookie?.includes('-')) {
    const [language] = localeCookie.split('-')
    Cookies.set(CookieNames.SELECTED_LANGUAGE, language, { expires: 365 })
  }
  return <ClientApp />
}
