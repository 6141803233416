import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { NAV_HEIGHT } from '../ui-page-modules/top-nav-bar/constants'

export function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export function scrollToElementTop(
  element: HTMLElement | null,
  topSpace = 0,
  behavior: ScrollBehavior = 'smooth',
) {
  if (!element) return
  const elementTop = element.offsetTop
  setTimeout(() => {
    window.scrollTo({ top: elementTop - NAV_HEIGHT - topSpace, behavior })
  }, 0)
}
