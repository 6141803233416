import { useTranslation } from 'react-i18next'
import { IconButton, Paragraph, PlusIcon, Stack } from '@qasa/qds-ui'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useToastContext } from '@qasa/app'
import { useMutation } from '@apollo/client'
import { CreditReportFetchStatusEnum, type VerificationsQueryQuery } from '@qasa/graphql'

import { getPath } from '../../../routing/get-path'
import { formatDateToYearMonthDay } from '../../../helpers/date'
import { CreditVerificationSteps } from '../../application-flow/steps/credit-verification-step'
import { DELETE_CREDIT_REPORT } from '../../application-flow/steps/credit-report.gql'
import { Status, StatusCard } from '../../../ui-shared/status-card'
import { ActionsDropdownMenu } from '../../../ui-shared/actions-dropdown-menu'

type CreditReportItemProps = {
  data?: NonNullable<VerificationsQueryQuery['me']>['creditReport']
  shouldHideActions?: boolean
}

export function CreditReportItem({ data, shouldHideActions = false }: CreditReportItemProps) {
  const { t } = useTranslation('verifications')
  const { pathname, search } = useLocation()
  const { push } = useHistory()
  const { addToast } = useToastContext()
  const currentPath = pathname + search
  const [deleteCreditReport] = useMutation(DELETE_CREDIT_REPORT, {
    onError: () => addToast({ message: t('commons:unexpected_error') }),
  })

  const { expiresOn, fetchStatus, id } = data || {}
  const isCreditReportDownloaded = fetchStatus === CreditReportFetchStatusEnum.downloaded

  const creditReportPath = getPath('creditVerification', {
    step: isCreditReportDownloaded
      ? CreditVerificationSteps.CreditVerified
      : CreditVerificationSteps.CreditInitial,
    previous_path: currentPath,
  })
  const goToCreditReportPage = () => push(creditReportPath)
  const handleDeleteCreditReport = () => {
    if (id) {
      deleteCreditReport({ variables: { creditReportId: id } })
    }
  }

  const status = isCreditReportDownloaded ? Status.Verified : Status.NotVerified
  const items = [
    {
      label: t('commons:edit'),
      onClick: goToCreditReportPage,
    },
    {
      label: t('commons:delete'),
      onClick: handleDeleteCreditReport,
    },
  ]

  return (
    <StatusCard title={t('card.titles.credit_report')} status={status}>
      {isCreditReportDownloaded ? (
        <Stack justifyContent="space-between" direction="row" alignItems="flex-end">
          <Stack gap="2x">
            <Paragraph>{t('credit_report_description')}</Paragraph>
            <Paragraph size="sm">
              {expiresOn ? `${t('valid_until')} ${formatDateToYearMonthDay(expiresOn)}` : ''}
            </Paragraph>
          </Stack>
          {!shouldHideActions && <ActionsDropdownMenu items={items} />}
        </Stack>
      ) : (
        <Stack justifyContent="flex-end" direction="row">
          <IconButton
            as={Link}
            to={creditReportPath}
            size="xs"
            variant="tertiary"
            icon={PlusIcon}
            label="Verify Credit Report"
          />
        </Stack>
      )}
    </StatusCard>
  )
}
