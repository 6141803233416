import { Heading, LoadingDots, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { StatusTag, type StatusTagColor } from './status-tag'

export enum Status {
  Activated = 'activated',
  Verified = 'verified',
  NotVerified = 'not_verified',
  Pending = 'pending',
  Declined = 'declined',
  Expired = 'expired',
  ExpiresSoon = 'expires_soon',
  Added = 'added',
  NotAdded = 'not_added',
  Rejected = 'rejected',
  Incomplete = 'incomplete',
}

const Wrapper = styled(Stack)(({ theme }) => ({
  minHeight: theme.sizes['160'],
  padding: theme.spacing['6x'],
  borderRadius: theme.radii.lg,
  backgroundColor: theme.colors.core.gray10,
}))
const TopRow = Stack
const BottomRow = Stack

type Props = {
  title: string
  status: Status
  children?: ReactNode
  isLoading?: boolean
}

export function StatusCard({ title, status, children, isLoading }: Props) {
  const { t } = useTranslation('verifications', { keyPrefix: 'card.statuses' })

  if (isLoading) {
    return (
      <Wrapper gap="6x">
        <Heading size="xs" as="h4">
          {title}
        </Heading>
        <LoadingDots style={{ alignSelf: 'center' }} />
      </Wrapper>
    )
  }

  const mapStatusToTagColor = (): StatusTagColor => {
    switch (status) {
      case Status.Verified:
      case Status.Added:
      case Status.Activated:
        return 'green'
      case Status.Pending:
      case Status.NotAdded:
      case Status.ExpiresSoon:
        return 'yellow'
      case Status.Rejected:
      case Status.Declined:
        return 'red'
      default:
        return 'gray'
    }
  }

  const tagColor = mapStatusToTagColor()

  return (
    <Wrapper gap="3x" justifyContent="space-between">
      <TopRow direction="row" justifyContent="space-between">
        <Heading size="xs" as="h4">
          {title}
        </Heading>
        <StatusTag tagColor={tagColor}>{t(status)}</StatusTag>
      </TopRow>
      <BottomRow gap="4x">{children}</BottomRow>
    </Wrapper>
  )
}
