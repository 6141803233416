import type { ReactNode } from 'react'
import { lazy, Suspense } from 'react'
import type { RouteProps } from 'react-router-dom'
import { Route, Switch, useLocation } from 'react-router-dom'
import { LoadingDots, useBreakpointValue } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useSearchParams } from 'next/navigation'

import { NotFound } from '../legacy-pages/not-found'
import { useAuthContext } from '../context/auth-context'
import { SchibstedSimplifiedLoginPopup } from '../ui-page-modules/_brand-specific/schibsted-simplified-login-popup'
import { usePathname } from '../vendor/next'
import { useAuth } from '../feature-modules/auth/use-auth'

import { DefaultPageWrapper } from './default-page-wrapper'
import type { RouteConfig } from './route-configs'
import { routes } from './route-configs'

const LoadingDotsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
})

const ProtectedFeatureProviders = lazy(() =>
  import('../protected-feature-providers').then(({ ProtectedFeatureProviders }) => ({
    default: ProtectedFeatureProviders,
  })),
)
const ProtectedAuthProvider = lazy(() =>
  import('../context/protected-auth-context').then(({ ProtectedAuthProvider }) => ({
    default: ProtectedAuthProvider,
  })),
)

function ProtectedRoute({ wizard, ...props }: RouteProps & Pick<RouteConfig, 'wizard'>) {
  const { isLoading, isAuthenticated, hasRedirectedViaLogout } = useAuthContext()
  const { pushToSignIn } = useAuth()

  if (isLoading) {
    return (
      <LoadingDotsWrapper>
        <LoadingDots />
      </LoadingDotsWrapper>
    )
  }

  if (isAuthenticated) {
    return (
      <Suspense
        fallback={
          <LoadingDotsWrapper>
            <LoadingDots />
          </LoadingDotsWrapper>
        }
      >
        <ProtectedAuthProvider wizard={wizard}>
          <ProtectedFeatureProviders>
            <Route exact {...props} />
          </ProtectedFeatureProviders>
        </ProtectedAuthProvider>
      </Suspense>
    )
  }

  if (hasRedirectedViaLogout) {
    return null
  }

  pushToSignIn()

  return null
}

function PublicRoute(props: RouteProps) {
  return (
    <>
      <Route {...props} />
      <SchibstedSimplifiedLoginPopup />
    </>
  )
}

export function Routes({ onRouteMismatch }: { onRouteMismatch: (key: string) => void }) {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const reactRouterLocation = useLocation()

  const routeComponents: ReactNode[] = []

  routes.forEach((route) => {
    const {
      component: Component,
      key,
      path,
      isExact = true,
      isProtected,
      isDisabled,
      ...rest
    }: RouteConfig = {
      ...route,
      ...(isMobile && 'mobileOverride' in route ? route.mobileOverride : {}),
    }

    if (isDisabled) {
      return
    }

    if (isProtected) {
      routeComponents.push(
        <ProtectedRoute path={path} key={key} wizard={rest.wizard}>
          <DefaultPageWrapper i18nTitle={rest.i18nTitle}>
            <Component />
          </DefaultPageWrapper>
        </ProtectedRoute>,
      )
    } else {
      routeComponents.push(
        <PublicRoute exact={isExact} path={path} key={key}>
          <DefaultPageWrapper i18nTitle={rest.i18nTitle}>
            <Component />
          </DefaultPageWrapper>
        </PublicRoute>,
      )
    }
  })

  const pathname = usePathname()
  const searchParams = useSearchParams().toString()

  // BUG: Sometimes we get a mismatch between the Next.js router and the React Router
  // This is a workaround to force a re-render of the client app when the pathname changes
  if (pathname !== reactRouterLocation.pathname) {
    onRouteMismatch(pathname + reactRouterLocation.pathname)
  }

  // Let's control the location so it picks up changes from Next.js navigations
  const location = {
    ...reactRouterLocation,
    pathname,
    search: searchParams ? `?${searchParams}` : '',
  }

  return (
    <Suspense
      fallback={
        <LoadingDotsWrapper>
          <LoadingDots />
        </LoadingDotsWrapper>
      }
    >
      <Switch location={location}>
        {routeComponents}
        <Route>
          <DefaultPageWrapper>
            <NotFound />
          </DefaultPageWrapper>
        </Route>
      </Switch>
    </Suspense>
  )
}
