import { Link, Paragraph, Spacer, useBreakpointValue } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import type { LanguageCode } from '../../helpers/i18n'
import { SchibstedLogo } from '../../assets/logos/schibsted/schibsted'
import { useCurrentLocale } from '../../translations/use-current-locale'

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'baseline',
})
const StyledSchibstedLogo = styled(SchibstedLogo)(({ theme }) => ({
  height: theme.sizes['4x'],
  transform: 'translateY(2px)',
  width: 'auto',
  flexShrink: 0,
}))

const infoLinkHrefs: Record<LanguageCode, string> = {
  en: '//info.privacy.schibsted.com/en/privacy-and-cookie-policy-english-schibsted-sverige/',
  fi: '//info.privacy.schibsted.com/fi/tietosuoja-ja-evastekaytannot/',
  fr: '', // TODO: Add link for France
  sv: '//info.privacy.schibsted.com/se/schibsted-sverige-personuppgiftspolicy/',
  nb: '//info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/',
}

export function SchibstedControllerInfo() {
  const { t } = useTranslation('schibsted_controller_info')
  const locale = useCurrentLocale()
  const isLogoVisible = useBreakpointValue({ base: false, md: true })
  const readMoreHref = infoLinkHrefs[locale]

  return (
    <Wrapper>
      {isLogoVisible && (
        <>
          <StyledSchibstedLogo />
          <Spacer size="3x" axis={'x'} />
        </>
      )}
      <Paragraph as="span" size="sm" style={{ color: 'inherit' }}>
        {t('body')}{' '}
        <Link href={readMoreHref} isExternal>
          {t('read_more')}
        </Link>
      </Paragraph>
    </Wrapper>
  )
}
